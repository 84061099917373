// External packages
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// Internal packages
import { Notification, Section, colors, Link, LoadingSpinner } from 'shared-library';
import { fetchPiiResource } from 'packages/apis';
import { getEnvironment } from 'packages/locations';
import { Study } from '../../types';
import { captureError, formatPatientIdentifier } from '../../lib';
import { Row, Column } from '../Layout';

// Redux actions
import { updatePatientData, updateResearchConsent } from '../../store/patients/actions';
import { addTags, removeTags, updateStatus } from '../../store/studies/actions';
import { buildPatientSelector } from '../../store/patients/selectors';
import { buildStudySelector } from '../../store/studies/selectors';

// Components
import PatientInformation from './PatientInformation';
import { allTags, createTagsDiff } from './patientTags';
import PatientInformationForm, {
  PatientInformation as PatientInformationInput,
} from './PatientInformationForm';

const DisabledLink = styled.span`
  color: ${colors.primaryHover};
  text-decoration: underline;
  cursor: not-allowed;
`;

// Functions
const getTags = (statuses: Study['status']) => allTags.filter(({ name }) => !!statuses[name]);

const PatientInformationContainer = () => {
  const environment = getEnvironment();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));
  const patientIdentifier = formatPatientIdentifier(patient);
  const dobMoment = patient && patient.dob && moment(patient.dob);
  const formattedPatient = {
    ...patient,
    patientIdentifier: `${patientIdentifier.initials} - ${patientIdentifier.dobCode}`,
    dobMoment,
    tags: getTags(study.status),
    researchConsent: !!study.status.researchConsent,
  };

  const isSaving = study.loading || (patient && patient.loading);
  // State
  const [viewState, setViewState] = useState<'editing' | 'edit'>('edit');
  const [actionsProcessing, setActionProcessing] = useState({
    passwordReset: false,
    checkin: false,
  });

  // Handle user details edit
  const handleCancelEdit = () => setViewState('edit');
  const handleEditClick = () => setViewState('editing');
  const handleSubmit = async ({
    dobMoment,
    firstname,
    middlename,
    preferredName,
    gender,
    lastname,
    tags,
    researchConsent,
  }: PatientInformationInput) => {
    const { patient: patientID, studyID } = study;
    const submittedPatientData = {
      dob: dobMoment.format('YYYY-MM-DD'),
      firstname,
      middlename,
      preferredName,
      lastname,
      gender,
    };
    const { added, removed } = createTagsDiff(formattedPatient.tags, tags);
    try {
      const updates = [];
      const isPatientDataDiff =
        submittedPatientData.dob !== patient.dob ||
        submittedPatientData.firstname !== patient.firstname ||
        submittedPatientData.gender !== patient.gender ||
        submittedPatientData.lastname !== patient.lastname ||
        submittedPatientData.preferredName !== patient.preferredName ||
        submittedPatientData.middlename !== patient.middlename;
      if (isPatientDataDiff) {
        updates.push(updatePatientData(patientID, submittedPatientData)(dispatch));
      }
      if (researchConsent !== study.status.researchConsent) {
        updates.push(updateResearchConsent(patientID, researchConsent)(dispatch));
        updates.push(updateStatus(studyID, { researchConsent })(dispatch));
      }
      if (added.length) {
        updates.push(addTags(studyID, added)(dispatch));
      }
      if (removed.length) {
        updates.push(removeTags(studyID, removed)(dispatch));
      }
      if (!updates.length) {
        setViewState('edit');
        return;
      }
      await Promise.all(updates);
      addToast(
        Notification.create('Saved successfully', 'Successfully updated member information.'),
        { appearance: 'success' },
      );
      setViewState('edit');
    } catch (err) {
      addToast(
        Notification.create(
          'Saving failed',
          'Something went wrong, and part (or all) of your update may not have saved. Give it another try and please report an issue if this persists.',
        ),
        { appearance: 'error' },
      );
      setViewState('editing');
    }
  };

  // Handle reset password
  const handleResetPassword = async () => {
    const { email } = patient;
    setActionProcessing({ ...actionsProcessing, passwordReset: true });
    try {
      await fetchPiiResource('/api/v1/userV2/resetPassword', 'POST', { email });
      addToast(Notification.create('Password reset', `A reset email has been sent to ${email}`), {
        appearance: 'success',
      });
      setActionProcessing({ ...actionsProcessing, passwordReset: false });
    } catch (err) {
      captureError(err);
      addToast(
        Notification.create(
          'Reset failed',
          'Give it another try and please report an issue if this persists.',
        ),
        { appearance: 'error' },
      );
      setActionProcessing({ ...actionsProcessing, passwordReset: false });
    }
  };

  const openMemberOnSalesforce = async () => {
    let salesforceAccountId = patient.salesforceAccountId ? patient.salesforceAccountId : study.salesforce?.opportunity.ContactId;
    const { parsedBody } = await fetchPiiResource(
      `/api/v1/salesforce/person/${salesforceAccountId}`,
    );
    if (parsedBody.error) {
      const { parsedBody: prenuvoIdParsedBody } = await fetchPiiResource(
        `/api/v1/salesforce/person/prenuvoID/${patient.prenuvoId}`,
      );
      salesforceAccountId = prenuvoIdParsedBody;
    }
    window.open(
      `https://${
        environment !== 'PRODUCTION'
          ? 'prenuvo--prenuvosb.lightning.force.com'
          : 'prenuvo.lightning.force.com'
      }/lightning/r/Account/${salesforceAccountId}/view`,
      '_blank',
    );
  };

  return (
    <Section title="Member Information" onEditClick={handleEditClick} viewState={viewState}>
      <>
        {viewState === 'edit' && <PatientInformation patient={formattedPatient} study={study} />}
        {viewState === 'editing' && (
          <PatientInformationForm
            isSaving={isSaving}
            onCancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            patient={formattedPatient}
          />
        )}
        <Row>
          <Column full>
            <div>
              {study.location?.type === 'whitelabel' ||
              (!patient.salesforceAccountId && !study.salesforce?.opportunity.ContactId) ? (
                <DisabledLink>This study does not have a Salesforce account</DisabledLink>
              ) : (
                <Link onClick={openMemberOnSalesforce}>View Member on Salesforce</Link>
              )}
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <div>
              <Link
                onClick={() => handleResetPassword()}
                disabled={actionsProcessing.passwordReset}
                processing={actionsProcessing.passwordReset}
              >
                <>
                  {actionsProcessing.passwordReset && (
                    <LoadingSpinner color={colors.white} size="small" />
                  )}
                  Reset member password
                </>
              </Link>
            </div>
          </Column>
        </Row>
      </>
    </Section>
  );
};

export default PatientInformationContainer;
