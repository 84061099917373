import { useMutation } from '@apollo/client';
import { documentUploadMutations } from 'shared-library';

type UploadDocumentInput = {
  ownerId: string;
  documentName: string;
  documentType: string;
};

type DeleteDocumentInput = {
  documentId: string;
};

const useUploadDocument = () => {
  const [uploadDocumentMutation] = useMutation(documentUploadMutations.UPLOAD_DOCUMENT_MUTATION);

  const pushDocumenToS3Bucket = async (url, data) => {
    const response = await fetch(url, {
      method: 'PUT',
      body: data,
    });

    return response;
  };

  const uploadDocument = async ({ ownerId, documentName, documentType }: UploadDocumentInput) => {
    const { data } = await uploadDocumentMutation({
      variables: { ownerId, documentName, documentType },
    });

    return data.uploadDocument;
  };

  return { uploadDocument, pushDocumenToS3Bucket };
};

const useDeleteDocument = () => {
  const [deleteDocumentMutation] = useMutation(documentUploadMutations.DELETE_DOCUMENT_MUTATION);

  const deleteDocument = async (documentId: DeleteDocumentInput) => {
    const { data } = await deleteDocumentMutation({
      variables: { documentId },
    });

    return data.deleteDocument;
  };

  return { deleteDocument };
};

export { useUploadDocument, useDeleteDocument };
