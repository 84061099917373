import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';

import { Notification, Section, Text } from 'shared-library';
import { getApiUri } from 'packages/locations';
import PDFMerger from 'packages/pdf-merger';

import { captureError } from '../../lib';
import { buildPatientSelector } from '../../store/patients/selectors';
import { buildStudySelector } from '../../store/studies/selectors';
import { fetchPatientAccountSummary } from '../../store/patients/actions';
import { UserId, Study } from '../../types';

import AccountSummary from './AccountSummary';

export const downloadReferralPackagePdf = async (
  userId: UserId,
  studyId: Study['studyID'],
): Promise<boolean> => {
  const staticPath = getApiUri('STATIC');
  try {
    const ReferralMerger = new PDFMerger('referral-package', [userId], studyId);
    const pdfPath = `${staticPath}/documents/Prenuvo-Referral-packet-2019.pdf`;
    await ReferralMerger.handleDownload(pdfPath);
    return true;
  } catch (error) {
    captureError(error);
    throw error;
  }
};

const AccountSummaryContainer = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));
  const [isDownloadingPackage, setDownloadingPackage] = useState(false);
  const hasData = patient && patient.accountSummary && patient.accountSummary.data;
  const isLoading = !patient || !patient.accountSummary || patient.accountSummary.loading;
  const error = patient && patient.accountSummary && patient.accountSummary.error;

  const handleDownloadPackageClick = async () => {
    setDownloadingPackage(true);
    try {
      await downloadReferralPackagePdf(patient.userId, study.studyID);
      setDownloadingPackage(false);
      addToast(
        Notification.create(
          'Package downloaded',
          'Successfully downloaded referral package for patient.',
        ),
        { appearance: 'success' },
      );
    } catch (err) {
      setDownloadingPackage(false);
      addToast(
        Notification.create('Download failed', "Couldn't retrieve referral package for patient."),
        { appearance: 'error' },
      );
    }
  };

  useEffect(() => {
    const getAccountSummaryForPatient = async () => {
      try {
        await fetchPatientAccountSummary(patient.userId, study.studyID)(dispatch);
      } catch (err) {
        addToast(
          Notification.create(
            'Error fetching account summary',
            "Something unexpected went wrong when fetching this patient's account summary. Give it another try and please report an issue if this persists.",
          ),
          { appearance: 'error' },
        );
      }
    };
    if (patient && !patient.accountSummary) {
      getAccountSummaryForPatient();
    }
  }, [patient]);

  return (
    <Section loading={isLoading} title="Referral information">
      <>
        {error && <Text>Error fetching account summary.</Text>}
        {hasData && (
          <AccountSummary
            isDownloadingPackage={isDownloadingPackage}
            onDownloadPackageClick={handleDownloadPackageClick}
            summary={patient && patient.accountSummary.data}
          />
        )}
      </>
    </Section>
  );
};

export default AccountSummaryContainer;
