import React from 'react';
import { Text, Button, LoadingSpinner, colors, Tooltip } from 'shared-library';
import styled from 'styled-components';

interface ScheduleScan {
  recommendedDate: string;
  hasError: boolean;
  onClick: () => void;
  isSaving: boolean;
  hasProactiveOpportunity: boolean;
  proactiveOpportunityId: string;
  isFutureScanApplicable: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RecommendedScheduleScan: React.FC<ScheduleScan> = ({
  recommendedDate,
  hasError,
  onClick,
  isSaving,
  hasProactiveOpportunity,
  proactiveOpportunityId,
  isFutureScanApplicable,
}: ScheduleScan) => {
  const proactiveNotificationSent = hasProactiveOpportunity && !!proactiveOpportunityId;
  const isButtonDisabled = isSaving || hasError || !isFutureScanApplicable;

  if (proactiveNotificationSent) {
    return (
      <Container>
        <Text noMargin>{`Proactive scan notification sent for ${recommendedDate}`}</Text>
      </Container>
    );
  }

  return (
    <Container>
      <Text noMargin>
        {isFutureScanApplicable
          ? `Recommended date for next scan is: ${!hasError ? recommendedDate : ''}`
          : 'There is no recommended date for next scan.'}
      </Text>
      {hasError ? (
        <Tooltip id="schedule" text="There is no recommended date for next scan." />
      ) : null}
      {/* This is necessary for a bug on react-tooltip library https://github.com/wwayne/react-tooltip/issues/304 */}
      <span data-tip="" data-for="schedule">
        <Button variant="primary" size="medium" onClick={onClick} disabled={isButtonDisabled}>
          {isSaving ? (
            <LoadingSpinner color={colors.white} size="small" />
          ) : (
            'Send Recommended Next Scan Details'
          )}
        </Button>
      </span>
    </Container>
  );
};

export default RecommendedScheduleScan;
