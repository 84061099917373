import React from 'react';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import {
  colors,
  Button,
  Link,
  LoadingSpinner,
  SelectInput,
  Text,
  TextInput,
} from 'shared-library';
import { Row, Column } from '../Layout';
import { Patient } from '../../store/patients/types';
import { countrySelectOptions, validatePhoneNumber } from '../../lib';

const reqErrMsg = 'This field is required';

const validationSchema = yup.object().shape({
  email: yup.string().required(reqErrMsg).email('Email is invalid'),
  address: yup.string().required(reqErrMsg),
  city: yup.string().required(reqErrMsg),
  state: yup.string().required(reqErrMsg),
  postalCode: yup.string().required(reqErrMsg),
  country: yup
    .object()
    .shape({
      label: yup.string().required(reqErrMsg),
      value: yup.string().required(reqErrMsg),
    })
    .required(reqErrMsg),
  phoneNumber: validatePhoneNumber('Phone number is invalid'),
});

const ErrorText = styled(Text).attrs({
  color: colors.error,
  weight: '600',
})`
  font-size: 12px;
`;

const Actions = styled(Column)`
  margin: 32px auto 16px;
  text-align: center;
`;

const CancelButton = styled(Link).attrs({
  as: 'button',
})`
  background-color: transparent;
  border: 0;
  margin: 0 20px 10px 0;
`;

export type ContactInformationInput = {
  email: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: {
    label: string;
    value: string;
  };
  phoneNumber: string;
};

interface ContactInformationFormProps {
  isSaving: boolean;
  onCancelEdit: () => void;
  onSubmit: (data: ContactInformationInput) => void;
  contact: Patient;
}

const ContactInformationForm = ({
  isSaving,
  onCancelEdit,
  onSubmit,
  contact,
}: ContactInformationFormProps) => {
  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      ...contact,
      country: countrySelectOptions.find((_) => _.value === contact.country),
    },
    validationSchema,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Column>
          <Controller
            as={TextInput}
            control={control}
            label="Personal Email Address"
            name="email"
          />
          <ErrorMessage as={ErrorText} errors={errors} name="email" />
        </Column>
        <Column>
          <Controller as={TextInput} control={control} label="Phone Number" name="phoneNumber" />
          <ErrorMessage as={ErrorText} errors={errors} name="phoneNumber" />
        </Column>
      </Row>
      <Row>
        <Column>
          <Controller
            as={TextInput}
            control={control}
            label="Street Address w/ Apt #"
            name="address"
          />
          <ErrorMessage as={ErrorText} errors={errors} name="address" />
        </Column>
        <Column>
          <Controller as={TextInput} control={control} label="City" name="city" />
          <ErrorMessage as={ErrorText} errors={errors} name="city" />
        </Column>
        <Column>
          <Controller
            as={TextInput}
            control={control}
            label="Zip / Postal Code"
            name="postalCode"
          />
          <ErrorMessage as={ErrorText} errors={errors} name="postalCode" />
        </Column>
      </Row>
      <Row>
        <Column>
          <Controller
            as={SelectInput}
            control={control}
            defaultValue={countrySelectOptions.find((c) => c.value === contact.country)}
            label="Country"
            name="country"
            options={countrySelectOptions}
          />
          <ErrorMessage
            as={ErrorText}
            errors={errors}
            name="country"
            message={errors.country?.label?.message || errors.country?.value?.message}
          />
        </Column>
        <Column>
          <Controller as={TextInput} control={control} label="State / Province" name="state" />
          <ErrorMessage as={ErrorText} errors={errors} name="state" />
        </Column>
      </Row>
      <Row>
        <Actions>
          <CancelButton onClick={onCancelEdit} variant="primary" size="medium">Cancel</CancelButton>
          <Button disabled={isSaving} type="submit" variant="primary" size="medium">
            {isSaving ? <LoadingSpinner color={colors.white} size="small" /> : 'Save'}
          </Button>
        </Actions>
      </Row>
    </form>
  );
};

export default ContactInformationForm;
