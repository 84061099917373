import React from 'react';

import { Text } from 'shared-library';
import { formatPhoneNumber } from 'packages/formatters';

import { User } from '../../types';
import { Row, Column, Label } from '../Layout';

interface ContactInformationProps {
  contact: User;
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  contact
}: ContactInformationProps) => (
  <div>
    <Row>
      <Column>
        <Label>Personal Email Address</Label>
        <Text>{contact.email}</Text>
      </Column>
      <Column>
        <Label>Phone Number</Label>
        <Text>{contact.phoneNumber ? formatPhoneNumber(contact.phoneNumber) : 'N/A'}</Text>
      </Column>
    </Row>
    <Row>
      <Column>
        <Label>Address</Label>
        <Text>{contact.address}</Text>
      </Column>
      <Column>
        <Label>City</Label>
        <Text>{contact.city}</Text>
      </Column>
      <Column>
        <Label>Zip / Postal Code</Label>
        <Text>{contact.postalCode}</Text>
      </Column>
    </Row>
    <Row>
      <Column>
        <Label>Country</Label>
        <Text>{contact.country}</Text>
      </Column>
      <Column>
        <Label>State / Province</Label>
        <Text>{contact.state}</Text>
      </Column>
    </Row>
  </div>
);

export default ContactInformation;
