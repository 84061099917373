import React from 'react';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import styled from 'styled-components';
import { Moment } from 'moment';
import * as yup from 'yup';
import {
  colors,
  Button,
  DatePicker,
  Label,
  Link,
  LoadingSpinner,
  RadioGroup,
  SelectInput,
  TagGroup,
  Text,
  TextInput,
} from 'shared-library';
import { allTags, Tag, researchConsentOptions } from './patientTags';
import { Row, Column } from '../Layout';
import { User } from '../../types';

const reqErrMsg = 'This field is required';

const validationSchema = yup.object().shape({
  preferredName: yup.string().notRequired().nullable(),
  firstname: yup.string().required(reqErrMsg),
  middlename: yup.string().notRequired().nullable(),
  lastname: yup.string().required(reqErrMsg),
  dobMoment: yup.mixed().required(reqErrMsg),
  gender: yup.mixed().required().oneOf(['male', 'female']),
  tags: yup.array().notRequired(),
  researchConsent: yup.mixed().oneOf(researchConsentOptions),
});

const ErrorText = styled(Text).attrs({
  color: colors.error,
  weight: '600',
})`
  font-size: 12px;
`;

const Actions = styled(Column)`
  margin: 32px auto 16px;
  text-align: center;
`;

const CancelButton = styled(Link).attrs({
  as: 'button',
})`
  background-color: transparent;
  border: 0;
  margin: 0 20px 10px 0;
`;

export type PatientInformation = User & {
  patientIdentifier?: string;
  dobMoment?: Moment;
  tags: Array<Tag>;
  researchConsent: boolean;
};

interface PatientInformationFormProps {
  isSaving: boolean;
  onCancelEdit: () => void;
  onSubmit: (data: PatientInformation) => void;
  patient: PatientInformation;
}

const PatientInformationForm = ({
  isSaving,
  onCancelEdit,
  onSubmit,
  patient,
}: PatientInformationFormProps) => {
  const defaultResearchConsent = researchConsentOptions.find(
    ({ value }) => value === (!!patient.researchConsent).toString(),
  );
  const { control, errors, handleSubmit } = useForm({
    defaultValues: { ...patient, researchConsent: defaultResearchConsent },
    validationSchema,
  });
  const submitAfterConvertingResearchConsentFormat = (form) => {
    onSubmit({ ...form, researchConsent: form?.researchConsent?.value === 'true' });
  };

  return (
    <form onSubmit={handleSubmit(submitAfterConvertingResearchConsentFormat)}>
      <Row>
        <Column>
          <TextInput disabled label="Member Identifier" value={patient.patientIdentifier} />
        </Column>
        <Column>
          <Controller
            as={TextInput}
            control={control}
            label="Preferred Name"
            name="preferredName"
          />
          <ErrorMessage as={ErrorText} errors={errors} name="preferredName" />
        </Column>
      </Row>
      <Row>
        <Column>
          <Controller as={TextInput} control={control} label="Legal First Name" name="firstname" />
          <ErrorMessage as={ErrorText} errors={errors} name="firstname" />
        </Column>
        <Column>
          <Controller as={TextInput} control={control} label="Middle Name" name="middlename" />
          <ErrorMessage as={ErrorText} errors={errors} name="middlename" />
        </Column>
        <Column>
          <Controller as={TextInput} control={control} label="Legal Last Name" name="lastname" />
          <ErrorMessage as={ErrorText} errors={errors} name="lastname" />
        </Column>
      </Row>
      <Row>
        <Column>
          <Controller
            as={DatePicker}
            control={control}
            isOutsideRange={() => false}
            label="Date Of Birth"
            name="dobMoment"
          />
          <ErrorMessage as={ErrorText} errors={errors} name="dobMoment" />
        </Column>
        <Column>
          <Label>Biological Sex</Label>
          <Controller as={RadioGroup} control={control} initialValue={patient.gender} name="gender">
            <RadioGroup.Input label="Female" value="female" key="female" id="female" />
            <RadioGroup.Input label="Male" value="male" key="male" id="male" />
          </Controller>
          <ErrorMessage as={ErrorText} errors={errors} name="gender" />
        </Column>
        <Column>
          <Label>Tags</Label>
          <Controller
            as={
              <TagGroup initialSelected={patient.tags}>
                {allTags.map(({ name, label }) => (
                  <TagGroup.Input key={name} name={name} label={label} />
                ))}
              </TagGroup>
            }
            control={control}
            initialValue={patient.tags}
            name="tags"
          />
          <ErrorMessage as={ErrorText} errors={errors} name="tags" />
        </Column>
      </Row>
      <Row>
        <Column>
          <Controller
            name="researchConsent"
            as={
              <SelectInput
                defaultValue={defaultResearchConsent}
                options={researchConsentOptions}
                label="Research Consent"
                variant="primary"
              />
            }
            control={control}
            label="Research Consent"
          />
          <ErrorMessage as={ErrorText} errors={errors} name="researchConsent" />
        </Column>
      </Row>
      <Row>
        <Actions>
          <CancelButton onClick={onCancelEdit} variant="primary" size="small">
            Cancel
          </CancelButton>
          <Button disabled={isSaving} type="submit" variant="primary" size="small">
            {isSaving ? <LoadingSpinner color={colors.white} size="small" /> : 'Save'}
          </Button>
        </Actions>
      </Row>
    </form>
  );
};

export default PatientInformationForm;
