import { useApolloClient, useQuery } from '@apollo/client';
import { documentUploadQueries } from 'shared-library';

export const useGetDocuments = (ownerId: string) => {
  const client = useApolloClient();
  const { data: { documents = [] } = [], loading, error } = useQuery(
    documentUploadQueries.GET_DOCUMENTS_QUERY,
    {
      variables: { ownerId },
    },
  );

  const getDocument = (docId: string) => {
    return client.query({
      query: documentUploadQueries.GET_DOCUMENT_CONTENT_QUERY,
      variables: { docId },
      fetchPolicy: 'no-cache',
    });
  };

  return { documents: documents.documents, loading, error, getDocument };
};

export default useGetDocuments;
