import React from 'react';
import PatientInformation from './PatientInformationContainer';
import ContactInformation from './ContactInformationContainer';
import SocialInformation from './SocialInformation';
import AccountSummary from './AccountSummaryContainer';
import ProactiveBookingContainer from './ProactiveBookingContainer';
import DocumentsProvider from './DocumentsProvider';

const PatientDetails = () => (
  <>
    <PatientInformation />
    <ContactInformation />
    <DocumentsProvider />
    <SocialInformation />
    <AccountSummary />
    <ProactiveBookingContainer />
  </>
);

export default PatientDetails;
