import React from 'react';
import uuid from 'react-uuid';
import { Text, Link, Heading, Section } from 'shared-library';

import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Row, Column } from '../Layout';
import { buildStudySelector } from '../../store/studies/selectors';
import { buildPatientSelector } from '../../store/patients/selectors';

const SocialInformation = () => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));

  return (
    <Section title="Social Information">
      {patient.salesforce.person && patient.salesforce.person.socials && (
        <>
          <Heading size={7}>Social networks</Heading>
          {patient.salesforce.person.socials.length === 0 && (
            <Text>There are no social networks for this member.</Text>
          )}
          {patient.salesforce.person.socials.length > 0 && (
            <>
              {patient.salesforce.person.socials.map((social, index) => (
                <Row key={uuid()}>
                  <Column>{social.category}</Column>
                  <Column>
                    <Link target="_blank" href={social.url}>
                      {social.url}
                    </Link>
                  </Column>
                </Row>
              ))}
            </>
          )}
          <Heading size={7}>Websites</Heading>
          {patient.salesforce.person.websites.length === 0 && (
            <Text>There are no websites for this member.</Text>
          )}
          {patient.salesforce.person.websites.length > 0 && (
            <>
              {patient.salesforce.person.websites.map((website, index) => (
                <Row key={uuid()}>
                  <Column>{website.category}</Column>
                  <Column>
                    <Link target="_blank" href={website.url}>
                      {website.url}
                    </Link>
                  </Column>
                </Row>
              ))}
            </>
          )}
        </>
      )}
    </Section>
  );
};

export default SocialInformation;
