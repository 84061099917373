/* eslint-disable import/no-extraneous-dependencies */
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import { fetchAppsResource } from 'packages/apis';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { colors, InfoCard, Section, Notification } from 'shared-library';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import labels from '../../labels';
import { buildPatientSelector } from '../../store/patients/selectors';
import { buildStudySelector, selectCurrentStudies } from '../../store/studies/selectors';
import { FutureStudyType } from '../../store/studies/types';
import FeatureStudy from './FeatureStudy';
import RecommendedScheduleScan from './RecommendedScheduleScan';
import { fetchStudy } from '../../store/studies/actions';
import { LocationType } from '../../types';
import { addProactiveOpportunityAction } from '../../store/patients/actions';
import { formatRecommendedScanDate } from '../../lib/formatters';

interface CreateProactiveOpportunityResponse {
  opportunityID: string;
}

const RecommendedScheduleScanWrapper = styled.div<{ hasBottomSpace?: boolean }>`
  margin-bottom: ${(props) => (props.hasBottomSpace ? '40px' : '0')};
`;

const NOT_APPLICABLE_SCAN_DATE = 7956864000;

const ProactiveBookingContainer: React.FC = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [isSavingProactiveStudy, setIsSavingProactiveStudy] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<{ open: boolean; message: string }>({
    open: false,
    message: '',
  });
  const [recommendedDateError, setRecommendedDateError] = useState(false);
  const [recommendedDate, setRecommendedDate] = useState<string | null>();
  const [allLocations, setAllLocations] = useState<LocationType[]>([]);
  const { studyId: selectedStudyId } = useParams<{ studyId: string }>();
  const selectStudy = buildStudySelector(selectedStudyId);
  const studies = useSelector(selectCurrentStudies);

  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));
  const history = useHistory();

  const isLoading = !patient || !patient.accountSummary || patient.accountSummary.loading;

  const futureStudies = patient?.accountSummary?.data?.futureStudies.filter(
    (_study) => _study.studyID !== selectedStudyId,
  );

  const proactiveOpportunityId = patient?.proactiveOpportunityId;

  const hasProactiveOpportunity =
    study?.config?.proactiveOpportunityCreated && study.status.published;

  const hasProactiveStudy =
    futureStudies?.find((_futureStudy) => _futureStudy.proactive) ||
    (!!study?.config?.proactiveBooking && !study.status.published) ||
    (hasProactiveOpportunity && !proactiveOpportunityId) ||
    !!patient?.accountSummary?.data?.whitelabelStudiesOnly;

  const hasNoProactive = !hasProactiveStudy || !hasProactiveOpportunity;

  const isFutureScanApplicable =
    patient?.nextRecommendedScan && patient?.nextRecommendedScan !== NOT_APPLICABLE_SCAN_DATE;

  const handleTabClickFallBack = () => null;
  const { handleTabClick } = handleTabClickFallBack;

  const getAllLocations = async () => {
    try {
      const { parsedBody: locations } = await fetchAppsResource<LocationType[]>(
        `/locations/?config=True`,
        'GET',
      );

      setAllLocations(locations);
    } catch (error) {
      Sentry.captureException(
        new Error(
          `Failed to get a locations for this patient: ${patient.prenuvoId} in this study ${selectedStudyId}`,
        ),
      );
    }
  };

  const getProactiveScanDate = () => {
    const isNextRecommendedScanValid = moment.unix(patient.nextRecommendedScan).utc().isAfter();
    if (isNextRecommendedScanValid) {
      const { timezone } = study.location;
      const formattedRecommendedScanDate = formatRecommendedScanDate(
        patient.nextRecommendedScan,
        timezone,
      );
      setRecommendedDate(formattedRecommendedScanDate);
    } else {
      setRecommendedDateError(true);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getAllLocations();
    }

    const latestStudyIsPublished = patient?.accountSummary?.data?.latestStudy?.published;

    const latestStudyProactiveOpportunityCreated =
      patient?.accountSummary?.data?.latestStudy?.proactiveOpportunity;

    if (
      patient?.accountSummary?.data &&
      (!latestStudyIsPublished ||
        latestStudyProactiveOpportunityCreated ||
        hasProactiveOpportunity ||
        hasProactiveStudy)
    ) {
      setRecommendedDateError(true);
    }

    if (latestStudyIsPublished && hasNoProactive && !isLoading && isFutureScanApplicable) {
      getProactiveScanDate();
    }
  }, [patient]);

  const onCreateProactiveOpportunity = async () => {
    try {
      setIsSavingProactiveStudy(true);
      const skuID =
        parseInt(study.items.primary.skuID, 10) >= 300 ? study.items.primary.skuID : '300';
      const nextRecommendedScan = moment(patient.nextRecommendedScan * 1000)
        .tz(study.location.timezone)
        .format('YYYY-MM-DDTHH:mm:ss');
      const { parsedBody: futureOppourtunity } = await fetchAppsResource<
        CreateProactiveOpportunityResponse
      >('/booking/create-proactive-opportunity', 'POST', {
        locationID: study.location.locationID,
        skuID,
        patient,
        nextRecommendedScan,
        studyID: selectedStudyId,
      });

      if (futureOppourtunity?.opportunityID) {
        dispatch(
          addProactiveOpportunityAction(patient.prenuvoId, futureOppourtunity.opportunityID),
        );

        addToast(Notification.create('Proactive scan notification sent', ''), {
          appearance: 'success',
        });
      } else {
        throw Error('Opportunity ID not found');
      }
    } catch (error) {
      setOpenErrorModal({ open: true, message: 'Proactive Opportunity was not created.' });
      Sentry.captureException(
        new Error(
          `Failed to create a proactive opportunity for this patient: ${patient.prenuvoId} in this study ${selectedStudyId}`,
        ),
      );
    } finally {
      setIsSavingProactiveStudy(false);
    }
  };

  const navigateToStudy = async (studyID: string) => {
    try {
      const studyIds = studies.map(({ studyID: studyId }) => studyId);
      if (!studyIds.includes(studyID)) {
        await fetchStudy(studyID)(dispatch);
      }

      if (history.location.pathname.includes(studyID)) {
        handleTabClick('study-details');
      }
      history.push({ ...history.location, pathname: `/study/${studyID}/study-details` });
    } catch (error) {
      setOpenErrorModal({ open: true, message: 'Fail to navigate to this study' });
      Sentry.captureException(
        new Error(
          `Failed to navigate to a proactive booking study for this patient: ${patient.prenuvoId} in this study ${selectedStudyId}`,
        ),
      );
    }
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal({ open: false, message: '' });
  };

  const getLocationInformation = (locationId: string): LocationType => {
    return allLocations.find((location) => location.locationID === locationId);
  };

  return (
    <Section loading={isLoading && allLocations.length > 0} title="Future Scan">
      {!isLoading && allLocations.length > 0 ? (
        <>
          {hasNoProactive ? (
            <RecommendedScheduleScanWrapper hasBottomSpace={!!futureStudies?.length}>
              <RecommendedScheduleScan
                recommendedDate={recommendedDate}
                hasError={recommendedDateError}
                onClick={onCreateProactiveOpportunity}
                isSaving={isSavingProactiveStudy}
                hasProactiveOpportunity={
                  patient.showProactiveNotificationSent || hasProactiveOpportunity
                }
                proactiveOpportunityId={proactiveOpportunityId}
                isFutureScanApplicable={isFutureScanApplicable}
              />
            </RecommendedScheduleScanWrapper>
          ) : null}

          {futureStudies?.length ? (
            <>
              {futureStudies.map((futureStudy: FutureStudyType) => {
                const futureStudyLocation = getLocationInformation(futureStudy.locationID);

                return (
                  <FeatureStudy
                    key={futureStudy.studyID}
                    date={moment
                      .tz(futureStudy.time * 1000, futureStudyLocation?.timezone)
                      .format('DD MMM YYYY hh:mm a')}
                    location={futureStudyLocation?.address?.name || ''}
                    type={labels.packages[futureStudy.skuID]}
                    address={futureStudyLocation?.address?.address || ''}
                    tags={futureStudy.proactive}
                    onClick={() => navigateToStudy(futureStudy.studyID)}
                  />
                );
              })}
            </>
          ) : null}

          {openErrorModal.open ? (
            <InfoCard
              icon={{ name: 'alert', color: colors.error, size: 66, viewSize: 100 }}
              headerText={openErrorModal.message}
              bodyText="Please try again. If this error persists, please report an issue."
              handleClose={handleCloseErrorModal}
            />
          ) : null}
        </>
      ) : null}
    </Section>
  );
};

export default ProactiveBookingContainer;
