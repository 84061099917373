/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { colors, Button, LoadingSpinner, Text } from 'shared-library';
import { formatCurrency } from 'packages/formatters';

import { AccountSummary } from '../../store/patients/types';
import { Row, Column, Label } from '../Layout';

interface PatientInformationProps {
  isDownloadingPackage: boolean;
  onDownloadPackageClick: () => void;
  summary: AccountSummary;
}

const PatientInformation: React.FC<PatientInformationProps> = ({
  isDownloadingPackage,
  onDownloadPackageClick,
  summary,
}: PatientInformationProps) => (
  <>
    <Row>
      <Column>
        <Label>Referral ID</Label>
        <Text>{summary.referralInfo.referralCode}</Text>
      </Column>
      <Column>
        <Label>Amount Outstanding</Label>
        <Text>{formatCurrency(summary.outstandingBalance, summary.currency)}</Text>
      </Column>
      <Column>
        <Label>Credits</Label>
        <Text>{formatCurrency(summary.referralInfo.balance)}</Text>
      </Column>
    </Row>
    <Row>
      <Column>
        <Label>Referral Package</Label>
        <Button
          disabled={isDownloadingPackage}
          onClick={onDownloadPackageClick}
          size="xsmall"
          variant="secondary"
        >
          {isDownloadingPackage ? (
            <LoadingSpinner color={colors.white} size="small" />
          ) : (
            'Download Package'
          )}
        </Button>
      </Column>
    </Row>
  </>
);

export default PatientInformation;
