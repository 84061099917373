import React from 'react';
import { Text, Heading, Button } from 'shared-library';
import styled from 'styled-components';

import { Row, Column } from '../Layout';

interface FeatureStudyProps {
  date: string;
  location: string;
  type: string;
  address: string;
  tags: boolean;
  onClick: () => void;
}

const ButtonWrapper = styled(Row)`
  margin-bottom: 20px;
`;

const FeatureStudy: React.FC<FeatureStudyProps> = ({
  date,
  location,
  type,
  address,
  tags,
  onClick,
}: FeatureStudyProps) => {
  return (
    <>
      <Row>
        <Column>
          <Heading size={7}>Study Date/Time</Heading>
          <Text>{date}</Text>
        </Column>
        <Column>
          <Heading size={7}>Scan Type</Heading>
          <Text>{type}</Text>
        </Column>
        <Column>
          <Heading size={7}>Scan Location</Heading>
          <Text>{location}</Text>
        </Column>
      </Row>
      <Row>
        <Column custom="66.66%">
          <Heading size={7}>Scan Address</Heading>
          <Text>{address}</Text>
        </Column>
        {tags ? (
          <Column>
            <Heading size={7}>Tags</Heading>
            <Text>Proactive</Text>
          </Column>
        ) : null}
      </Row>
      <ButtonWrapper>
        <Button size="medium" variant="secondary" onClick={onClick}>
          {tags ? 'View Proactive Booking' : 'View Future Study'}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default FeatureStudy;
