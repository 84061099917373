import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { DocumentUpload, Section, Notification } from 'shared-library';
import { useDeleteDocument, useUploadDocument, useGetDocuments } from 'packages/document-upload';

import { useToasts } from 'react-toast-notifications';

import { buildStudySelector } from '../../store/studies/selectors';
import { buildPatientSelector } from '../../store/patients/selectors';

const PatientDocuments: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const { studyId: selectedStudyId } = useParams<{ studyId: string }>();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));
  const { uploadDocument, pushDocumenToS3Bucket } = useUploadDocument();
  const { getDocument, documents, loading: isFetchingDocument, error } = useGetDocuments(
    patient.userId,
  );
  const { addToast } = useToasts();
  const { deleteDocument } = useDeleteDocument();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isFetchingDocument && !error && !!documents.length) {
      setTableData(documents);
    }
  }, [documents, isFetchingDocument, error]);

  const uploadDocumentData = async (documentData) => {
    try {
      setIsLoading(true);
      const { document } = await uploadDocument({
        ownerId: patient.userId,
        documentType: documentData.type,
        documentName: documentData.name,
      });
      await pushDocumenToS3Bucket(document.url, documentData.data);
      setTableData((prevData) => [...prevData, document]);
    } catch (err) {
      addToast(
        Notification.create(
          'Document Upload Failed',
          'Upload unsuccessful. Please retry. Report if issue persists.',
        ),
        { appearance: 'error' },
      );
    } finally {
      setIsLoading(false);
    }
  };

  const deleteDocumentData = async (id) => {
    try {
      setIsLoading(true);
      await deleteDocument(id);
      setTableData((prevData) => prevData.filter((item) => item.id !== id));
    } catch (err) {
      addToast(
        Notification.create(
          'Document Deletion Failed',
          'Deletion unsuccessful. Please retry. Report if issue persists.',
        ),
        { appearance: 'error' },
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Section title="Medical Documents">
      <DocumentUpload
        isLoading={isLoading || isFetchingDocument}
        data={tableData}
        enableViewDocButton
        enableDeleteDocButton
        onUploadDocument={uploadDocumentData}
        onDeleteDocument={deleteDocumentData}
        patient={patient}
        fetchDocument={getDocument}
      />
    </Section>
  );
};

export default PatientDocuments;
