import { getDocumentUpload } from 'packages/locations';
import React, { useMemo, useState } from 'react';
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider } from '@apollo/client';
import PatientDocuments from './PatientDocuments';

const DocumentsProvider: React.FC = () => {
  const [apiEndpoint] = useState(getDocumentUpload());

  const createApolloClient = (endpoint: string) => {
    return new ApolloClient({
      link: new HttpLink({
        uri: endpoint,
        credentials: 'include',
      }),
      cache: new InMemoryCache(),
    });
  };

  const apolloClient = createApolloClient(apiEndpoint);
  return useMemo(() => {
    return (
      <>
        {apiEndpoint && (
          <ApolloProvider client={apolloClient}>
            <PatientDocuments />
          </ApolloProvider>
        )}
      </>
    );
  }, [apiEndpoint, apolloClient]);
};

export default React.memo(DocumentsProvider);
