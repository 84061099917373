/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Moment } from 'moment';
import { Tag, Text } from 'shared-library';
import { Row, Column, Label } from '../Layout';
import labels from '../../labels';
import { Study, User } from '../../types';
import { researchConsentOptions } from './patientTags';

interface PatientInformationProps {
  patient: User & {
    patientIdentifier?: string;
    dobMoment?: Moment;
    tags: Array<Tag>;
  };
  study: Study;
}

const PatientInformation = ({ patient, study }: PatientInformationProps) => (
  <div>
    <Row>
      <Column>
        <Label>Member Identifier</Label>
        <Text>{patient.patientIdentifier}</Text>
      </Column>
      <Column>
        <Label>Preferred Name</Label>
        <Text>{patient.preferredName}</Text>
      </Column>
    </Row>
    <Row>
      <Column>
        <Label>First Name</Label>
        <Text>{patient.firstname}</Text>
      </Column>
      <Column>
        <Label>Middle Name</Label>
        <Text>{patient.middlename}</Text>
      </Column>
      <Column>
        <Label>Last Name</Label>
        <Text>{patient.lastname}</Text>
      </Column>
    </Row>
    <Row>
      <Column>
        <Label>Date Of Birth</Label>
        <Text>{patient.dobMoment.format('D MMMM YYYY')}</Text>
      </Column>
      <Column>
        <Label>Biological Sex</Label>
        <Text>{labels.sexes[patient.gender]}</Text>
      </Column>
      <Column>
        <Label>Tags</Label>
        <Text>
          {!patient.tags.length && 'N/A'}
          {patient.tags.map((tag) => (
            <Tag key={tag.name}>{tag.label}</Tag>
          ))}
        </Text>
      </Column>
    </Row>
    <Row>
      <Column>
        <Label>Prenuvo ID</Label>
        <Text>{patient.userId}</Text>
      </Column>
      <Column>
        <Label>Research Consent</Label>
        <Text>
          {
            researchConsentOptions.find(
              ({ value }) => value === (!!patient.researchConsent).toString(),
            )?.label
          }
        </Text>
      </Column>
      <Column>
        <Label>Current Medications</Label>
        <Text>{study.medications || 'None Reported'}</Text>
      </Column>
    </Row>
    <Row>
      <Column>
        <Label>Current Allergies</Label>
        <Text>{study.allergies || 'None Reported'}</Text>
      </Column>
    </Row>
  </div>
);

export default PatientInformation;
