import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';

import { Notification, Section } from 'shared-library';

import { updatePatientData } from '../../store/patients/actions';
import { buildPatientSelector } from '../../store/patients/selectors';
import { buildStudySelector } from '../../store/studies/selectors';

import ContactInformation from './ContactInformation';
import ContactInformationForm, { ContactInformationInput } from './ContactInformationForm';

const ContactInformationContainer = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));
  const [viewState, setViewState] = useState<'hide' | 'show' | 'edit'>('hide');

  const [multipleSectionButtons, setMultipleSectionButtons] = useState(['edit', 'hide']);

  const isSaving = patient && patient.loading;
  const handleShowClick = () => {
    setViewState('show');
    setMultipleSectionButtons(['show', 'edit']);
  };
  const handleHideClick = () => {
    setViewState('hide');
    setMultipleSectionButtons(['hide', 'edit']);
  };
  const handleEditClick = () => {
    setViewState(viewState === 'edit' ? 'show' : 'edit');
    setMultipleSectionButtons(['show', 'edit']);
  };
  const handleCancelEdit = () => {
    setViewState('show');
    setMultipleSectionButtons(['show', 'edit']);
  };
  const handleSubmit = async (submittedContactData: ContactInformationInput) => {
    // Unpack select value
    const flattenedSubmitData = {
      ...submittedContactData,
      country: submittedContactData.country.value,
    };
    try {
      const isContactDataDiff =
        flattenedSubmitData.email !== patient.email ||
        flattenedSubmitData.address !== patient.address ||
        flattenedSubmitData.city !== patient.city ||
        flattenedSubmitData.country !== patient.country ||
        flattenedSubmitData.postalCode !== patient.postalCode ||
        flattenedSubmitData.state !== patient.state ||
        flattenedSubmitData.phoneNumber !== patient.phoneNumber;

      if (!isContactDataDiff) {
        setViewState('show');
        return;
      }

      await updatePatientData(patient.userId, flattenedSubmitData)(dispatch);
      addToast(
        Notification.create('Saved successfully', 'Successfully updated contact information.'),
        { appearance: 'success' },
      );
      setViewState('show');
    } catch (err) {
      addToast(
        Notification.create(
          'Saving failed',
          err ||
            'Something went wrong, and part (or all) of your update may not have saved. Give it another try and please report an issue if this persists.',
        ),
        { appearance: 'error' },
      );
      setViewState('edit');
    }
  };

  return (
    <Section
      onEditClick={handleEditClick}
      onHideClick={handleHideClick}
      onShowClick={handleShowClick}
      title="Contact Information"
      viewState={viewState}
      displayMultipleButtons={multipleSectionButtons}
    >
      <>
        {viewState === 'show' && <ContactInformation contact={patient} />}
        {viewState === 'edit' && (
          <ContactInformationForm
            isSaving={isSaving}
            contact={patient}
            onSubmit={handleSubmit}
            onCancelEdit={handleCancelEdit}
          />
        )}
      </>
    </Section>
  );
};

export default ContactInformationContainer;
