export type Tag = {
  name: string;
  label: string;
};

export type TagsDiff = {
  added: Array<Tag['name']>;
  removed: Array<Tag['name']>;
};

export const allTags: Array<Tag> = [
  { name: 'tagVIP', label: 'VIP' },
  { name: 'tagBETA', label: 'Beta' },
  { name: 'tagCLA', label: 'Claustrophobic' },
  { name: 'tagVC', label: 'VC' },
  { name: 'tagDEC', label: 'Deceased' },
  { name: 'tagDOC', label: 'DOC' },
  { name: 'tagNC', label: 'Not Complete' },
  { name: 'tagPHYS1ST', label: 'Phys 1st' },
];

export const createTagsDiff = (oldTags: Array<Tag>, newTags: Array<Tag>): TagsDiff => {
  const oldNames = oldTags.map((_) => _.name);
  const newNames = newTags.map((_) => _.name);
  const added = newNames
    .filter((name) => !oldNames.includes(name))
    .map((_) => _.replace('tag', ''));
  const removed = oldNames
    .filter((name) => !newNames.includes(name))
    .map((_) => _.replace('tag', ''));
  return { added, removed };
};

export const researchConsentOptions = [
  { label: 'Granted', value: 'true' },
  { label: 'Not Granted', value: 'false' },
];
